import { createRouter, createWebHistory } from 'vue-router';
// import IndexPage from "@/views/indexPage/IndexPage.vue";

import MainLayout from '@/layouts/MainLayout/index.vue';

import Index from '@/views/index/index.vue';

const routes = [
  {
    path: '',
    component: MainLayout,
    redirect: 'index',
    children: [
      {
        path: 'index',
        name: 'index',
        meta: {
          title: '首页',
        },
        component: Index,
      },
      {
        path: 'expert-list',
        name: 'expert-list',
        meta: {
          title: '专家团队',
        },
        component: () => import('@/views/expert-team/expert-list.vue'),
      },
      {
        path: 'certificate',
        name: 'certificate',
        meta: {
          title: '证书查询',
        },
        component: () => import('@/views/certificate/index.vue'),
      },
      {
        path: 'expert-detail/:id',
        name: 'expert-detail',
        meta: {
          title: '专家详情',
        },
        component: () => import('@/views/expert-team/expert-detail.vue'),
      },
      {
        path: 'repository',
        name: 'repository',
        meta: {
          title: '知识库',
        },
        component: () => import('@/views/repository/repository-list.vue'),
      },
      {
        path: 'videos',
        name: 'videos',
        meta: {
          title: '大咖视频',
        },
        component: () => import('@/views/videos/video-list.vue'),
      },
      {
        path: 'study',
        name: 'Study',
        meta: {
          title: '学习中心',
        },
        component: () => import('@/views/study/study-center/study-center.vue'),
      },
      {
        path: 'coures-detail',
        name: 'CouresDetail',
        meta: {
          title: '课程详情',
        },
        component: () =>
          import('@/views/study/course-detail/course-detail.vue'),
      },
      {
        path: 'video-play',
        name: 'VideoPlay',
        meta: {
          title: '课程详情',
        },
        component: () => import('@/views/study/video-play/video-play.vue'),
      },
      {
        path: 'exam-list',
        name: 'exam-list',
        meta: {
          title: '在线考试',
          needAuth: true,
        },
        component: () => import('@/views/exam/exam-list.vue'),
      },
      {
        path: 'simulate-list',
        name: 'simulate-list',
        meta: {
          title: '模拟考试',
          needAuth: true,
        },
        component: () => import('@/views/exam-simulate/simulate-list.vue'),
      },
      {
        path: 'question-collection',
        name: 'question-collection',
        meta: {
          title: '试题征集',
          needAuth: true,
        },
        component: () => import('@/views/question-collection/index.vue'),
      },
      {
        path: 'collection-detail',
        name: 'collection-detail',
        meta: {
          title: '试题征集',
          needAuth: true,
        },
        component: () =>
          import('@/views/question-collection/collection-detail.vue'),
      },
      {
        path: 'exam-detail/:id',
        name: 'exam-detail',
        meta: {
          title: '考试详情',
          needAuth: true,
        },
        component: () => import('@/views/exam/exam-detail.vue'),
      },
      {
        path: 'simulate-detail/:id',
        name: 'simulate-detail',
        meta: {
          title: '模拟考试详情',
          needAuth: true,
        },
        component: () => import('@/views/exam-simulate/simulate-detail.vue'),
      },
      {
        path: 'info-search',
        name: 'info-search',
        meta: {
          title: '信息查询',
        },
        redirect: '/info-search/fad-search',
        component: () => import('@/layouts/PublicLayout.vue'),
        children: [
          {
            path: 'fad-search',
            name: 'fad-search',
            meta: {
              title: '信息查询',
            },
            component: () => import('@/views/info-search/index.vue'),
          },
          {
            path: 'drug-detail/:id',
            name: 'drug-detail',
            meta: {
              title: '药品详情',
              needAuth: true,
            },
            component: () => import('@/views/info-search/drug-detail.vue'),
          },
        ],
      },

      {
        path: 'about',
        name: 'AboutPage',
        component: () =>
          import(/* webpackChunkName: "about" */ '@/views/about/index.vue'),
      },
      {
        path: 'feedback',
        name: 'Feedback',
        component: () =>
          import(
            /* webpackChunkName: "feedback" */ '@/views/feedback/index.vue'
          ),
      },

      // 个人中心
      {
        path: 'dashboard',
        name: 'dashboard',
        meta: {
          title: '个人中心',
          needAuth: true,
        },
        redirect: '/dashboard/personal-info',
        component: () => import('@/layouts/DashboardLayout/index.vue'),
        children: [
          {
            path: 'personal-info',
            name: 'personal-info',
            meta: {
              title: '基本信息',
              needAuth: true,
            },
            component: () => import('@/views/dashboard/personal-info.vue'),
          },
          {
            path: 'my-simulate',
            name: 'my-simulate',
            meta: {
              title: '模考信息',
              needAuth: true,
            },
            component: () =>
              import('@/views/dashboard/my-simulate/my-simulate-list.vue'),
          },
          {
            path: 'my-exam',
            name: 'my-exam',
            meta: {
              title: '考试信息',
              needAuth: true,
            },
            redirect: '/dashboard/my-exam/my-exam-list',
            component: () => import('@/layouts/PublicLayout.vue'),
            children: [
              {
                path: 'my-exam-list',
                name: 'my-exam-list',
                meta: {
                  title: '考试信息',
                  needAuth: true,
                },
                component: () =>
                  import('@/views/dashboard/my-exam/my-exam-list.vue'),
              },
              {
                path: 'exam-analysis/:id',
                name: 'exam-analysis',
                meta: {
                  title: '考试分析',
                  needAuth: true,
                },
                component: () =>
                  import('@/views/dashboard/my-exam/exam-analysis/index.vue'),
              },
              {
                path: 'certificate-request/:id',
                name: 'certificate-request',
                meta: {
                  title: '证书申请',
                  needAuth: true,
                },
                component: () =>
                  import('@/views/dashboard/my-exam/certificate-request.vue'),
              },
            ],
          },
          {
            path: 'my-question',
            name: 'my-question',
            meta: {
              title: '试题征集',
              needAuth: true,
            },
            redirect: '/dashboard/my-question/my-question-list',
            component: () => import('@/layouts/PublicLayout.vue'),
            children: [
              {
                path: 'my-question-list',
                name: 'my-question-list',
                meta: {
                  title: '试题征集',
                  needAuth: true,
                },
                component: () =>
                  import('@/views/dashboard/my-question/my-question-list.vue'),
              },
              {
                path: 'my-question-detail/:id',
                name: 'my-question-detail',
                meta: {
                  title: '试题征集',
                  needAuth: true,
                },
                component: () =>
                  import(
                    '@/views/dashboard/my-question/my-question-detail.vue'
                  ),
              },
            ],
          },
          {
            path: 'my-course',
            name: 'my-course',
            meta: {
              title: '课程观看',
              needAuth: true,
            },
            component: () => import('@/views/dashboard/my-course/index.vue'),
          },
        ],
      },
    ],
  },
  {
    path: '/exam-workbenches/:id',
    name: 'exam-workbenches',
    meta: {
      title: '在线考试',
      needAuth: true,
    },
    component: () =>
      import(
        /* webpackChunkName: "exam-workbenches" */ '@/views/exam-workbenches/index.vue'
      ),
  },
  {
    path: '/simulate-workbenches/:id',
    name: 'simulate-workbenches',
    meta: {
      title: '模拟考试',
      needAuth: true,
    },
    component: () =>
      import(
        /* webpackChunkName: "simulate-workbenches" */ '@/views/simulate-workbenches/index.vue'
      ),
  },
  {
    path: '/repository-detail/:id',
    name: 'repository-detail',
    meta: {
      title: '在线文档',
      needAuth: true,
    },
    component: () => import('@/views/repository/repository-detail.vue'),
  },
  {
    path: '/login',
    name: 'LoginPage',
    component: () => import('@/views/login/index.vue'),
  },
  {
    path: '/register',
    component: () => import('@/views/register/index.vue'),
  },
  {
    path: '/rest-password',
    component: () => import('@/views/rest-password/index.vue'),
  },
  //
  {
    path: '/test',
    component: () => import('@/views/test.vue'),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior() {
    return { left: 0, top: 0 };
  },
});

export default router;
