<template>
  <div class="public-header">
    <div class="public-header-fixed">
      <div class="public-wrapper header-wrapper">
        <div class="logo-wrapper">
          <span class="logo" @click="goHome"
            ><img src="@/assets/images/logo.png" alt="" /></span
          ><span>药物警戒学习中心</span>
        </div>
        <div class="menu-wrapper">
          <div class="menu-list">
            <div v-for="item in menuList" :key="item.name" class="menu-item">
              <div
                :class="['menu-link', getCurrentActive(item) ? 'active' : '']"
                @click="onMenuClick(item)"
              >
                <span class="menu-text">
                  {{ item.name }}
                </span>
                <el-icon
                  v-if="item.children && item.children.length"
                  class="menu-icon"
                  ><ArrowDownBold
                /></el-icon>
              </div>
              <template v-if="item.children && item.children.length">
                <div class="sub-menu">
                  <div class="sub-menu-list">
                    <div
                      v-for="subItem in item.children"
                      :key="subItem.name"
                      class="sub-menu-item"
                      @click="onMenuClick(subItem)"
                    >
                      {{ subItem.name }}
                    </div>
                  </div>
                </div>
              </template>
            </div>
          </div>
        </div>
        <div class="right-wrapper">
          <div v-if="!userStore.isLogin" class="login-btns">
            <a @click="onLogin">登录</a>
            <span>|</span>
            <a @click="onRegister">注册</a>
          </div>
          <div v-else class="personal-info">
            <div class="user-avatar">{{ userName }}</div>
            <div class="personal-menu">
              <div class="menu-list">
                <div
                  class="menu-item"
                  data-path="/dashboard"
                  @click="goDashboard"
                >
                  个人中心
                </div>
                <div class="menu-item" @click="onLogout">退出</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, computed } from 'vue';
import { useRouter } from 'vue-router';
import { ArrowDownBold } from '@element-plus/icons-vue';
import { useUserStore } from '@/stores/user';
import { removeToken } from '@/utils/auth';

import { session } from '@/utils/storage';
import { http } from '@/http/client';

const userStore = useUserStore();

const menuList = ref([
  {
    name: '首页',
    path: '/index',
  },
  // {
  //   name: '专家团队',
  //   path: '/expert-list',
  // },
  {
    name: '成长学堂',
    children: [
      {
        name: '学习培训',
        path: '/study',
      },
      {
        name: '大咖视频',
        path: '/videos',
      },
    ],
  },
  {
    name: '知识库',
    path: '/repository',
  },
  {
    name: '试题征集',
    path: '/question-collection',
  },
  {
    name: '自评认证',
    children: [
      // {
      //   name: "在线刷题",
      // },
      // {
      //   name: "模拟考试",
      // },
      {
        name: '在线考试',
        path: '/exam-list',
      },
      {
        name: '模拟考试',
        path: '/simulate-list',
      },
    ],
  },
  {
    name: '信息查询',
    path: '/info-search',
    children: [
      {
        name: 'FDA信号查询',
        path: '/info-search/fad-search',
      },
    ],
  },
  {
    name: '证书查询',
    path: '/certificate',
  },
  // {
  //   name: '合作伙伴',
  // },
  // {
  //   name: '关于我们',
  //   path: '/about',
  // },
]);

const userName = computed(() => {
  return (userStore.userInfo.userName || '').slice(0, 1) || '';
});

const router = useRouter();
const getCurrentActive = (item) => {
  // console.log(router.currentRoute.value.fullPath, item.path);
  return router.currentRoute.value.fullPath.includes(item.path);
};

const onMenuClick = (item) => {
  if ((item.children && item.children.length) || !item.path) {
    return;
  }

  router.push(item.path || '/');
};

const goHome = () => {
  router.push('/index');
};

const onLogin = () => {
  //
  router.push('/login');
};
const onRegister = () => {
  //
  router.push('/register');
};
const goDashboard = (e) => {
  // console.log(e.currentTarget.dataset);
  const { path } = e.currentTarget.dataset;
  router.push(path);
};
const onLogout = async () => {
  await http.post('/platform/web/user/logout');
  removeToken();
  session.remove('userInfo');
  window.location.href = '/';
};
</script>

<style lang="less" scoped>
.public-header {
  height: 66px;
  position: relative;
  z-index: 1;
  .public-header-fixed {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1;
    width: 100%;
    height: 66px;
    background-color: #fff;
    border-bottom: 1px solid #e1e1e1;
  }
}
.header-wrapper {
  display: flex;
  align-items: center;
  height: 66px;
  .logo-wrapper {
    display: flex;
    align-items: center;
    .logo {
      // display: inline-block;
      // width: 40px;
      // height: 40px;
      // border-radius: 100%;
      // background-color: #e1e1e1;
      // overflow: hidden;
      // color: #0a47ed;
      // text-align: center;
      // line-height: 40px;
      // font-size: 20px;
      // font-weight: bold;
      // margin-right: 5px;
      img {
        width: 60px;
        margin-top: -5px;
      }
    }
    span {
      font-size: 20px;
      font-weight: bold;
    }
  }
  .menu-wrapper {
    flex: 1;
    margin-left: 26px;
    display: flex;
    align-items: center;
    .menu-list {
      display: flex;
      align-items: center;
      .menu-item {
        display: flex;
        align-items: center;
        white-space: nowrap;
        cursor: pointer;
        position: relative;
        .menu-link {
          padding: 8px 14px;
          line-height: 20px;
          color: #353535;
          .menu-text {
            font-size: 18px;
            font-weight: 500;
          }
          .menu-icon {
            transform: rotate(0deg);
            transition: all 0.5s 0s;
            margin-left: 3px;
            margin-top: -2px;
          }

          &.active {
            color: #0a47ed;
          }
        }

        .sub-menu {
          display: none;
          opacity: 0;
          position: absolute;
          z-index: 1;
          top: 30px;
          left: 50%;
          min-width: 200px;
          transform: translate(-50%, 0);
          transition: all 0.5s 0s;
          padding-top: 10px;
          .sub-menu-list {
            background-color: #fff;
            border-radius: 4px;
            box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.1);
          }
          .sub-menu-item {
            padding: 8px 14px;
            text-align: center;
            cursor: pointer;
            line-height: 30px;
            font-size: 18px;
            &:hover {
              color: #0a47ed;
              background-color: #f5f5f5;
            }
          }
        }
        &:hover {
          .menu-link {
            color: #0a47ed;
          }
          .menu-icon {
            transform: rotate(180deg);
            color: #0a47ed;
            margin-top: 0px;
          }
          .sub-menu {
            display: block;
            opacity: 1;
          }
        }
      }
    }
  }
  .right-wrapper {
    display: flex;
    align-items: center;
    .login-btns {
      a {
        color: #666;
        font-size: 14px;
        &:hover {
          color: #0a47ed;
          cursor: pointer;
        }
      }
      span {
        color: #666;
        display: inline-block;
        margin: 0 5px;
        font-size: 16px;
      }
    }
    .personal-info {
      margin-left: 20px;
      position: relative;
      .user-avatar {
        width: 40px;
        height: 40px;
        border-radius: 100%;
        overflow: hidden;
        background-color: #e1e1e1;
        display: flex;
        align-items: center;
        justify-content: center;
        img {
          height: 100%;
          width: 100%;
        }
      }
      .personal-menu {
        font-size: 18px;
        display: none;
        position: absolute;
        z-index: 1;
        top: 39px;
        left: 50%;
        min-width: 150px;
        transform: translate(-50%, 0);
        padding-top: 10px;
        .menu-list {
          background-color: #fff;
          border-radius: 4px;
          box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.1);
        }
        .menu-item {
          padding: 8px 14px;
          text-align: center;
          cursor: pointer;
          line-height: 25px;
          &:hover {
            color: #0a47ed;
            background-color: #f5f5f5;
          }
        }
      }

      &:hover {
        .personal-menu {
          display: block;
        }
      }
    }
  }
}
</style>
