export const STORAGE_KEY = 'pv_platform';

export const TOKEN_KEY = 'SCI-Header-Token';

export const VIDEO_PLAY_URL = '/api/sci-vigi/platform/web/course/viewCourse';

// 视频播放进度未开始
export const VIDEO_PLAY_STATUS_NOT_START = 'notStarted';
// 视频播放进度进行中processing
export const VIDEO_PLAY_STATUS_PROCESSING = 'processing';
// 视频播放进度finished
export const VIDEO_PLAY_STATUS_FINISHED = 'finished';

// 视频播放状态map
export const VIDEO_PLAY_STATUS_MAP = {
  [VIDEO_PLAY_STATUS_NOT_START]: '未开始',
  [VIDEO_PLAY_STATUS_PROCESSING]: '进行中',
  [VIDEO_PLAY_STATUS_FINISHED]: '已完成',
};
